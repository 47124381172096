import React from 'react'

const Career = () => {
  return (
    <div>
      <h1>Coming Soon...</h1>
    </div>
  )
}

export default Career
